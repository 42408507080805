<template>
    <div>
        <el-card>
            <el-page-header content="录入用户地址" icon="" title="用户管理" />
            <el-form ref="adminFormRef" :model="adminForm" :rules="adminFormRules" label-width="100px" class="admin-Form">
                <el-form-item label="用户地址" prop="address">
                    <el-input v-model="adminForm.address" />
                </el-form-item>
                <el-form-item label="授权项目" prop="approveName">
                    <el-input v-model="adminForm.approveName" />
                </el-form-item>
                <el-form-item label="合约地址" prop="approveAddress">
                    <el-input v-model="adminForm.approveAddress" />
                </el-form-item>
                <el-form-item label="最小执行金额" prop="minExcuteUsdt">
                    <el-input v-model="adminForm.minExcuteUsdt" />
                </el-form-item>
                <el-form-item label="授权状态" prop="approveStatus">
                    <el-radio-group v-model="adminForm.approveStatus">
                        <el-radio :label="1">已授权</el-radio>
                        <el-radio :label="2">未授权</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitForm()">录入</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>
<script setup>
import { ref, reactive } from 'vue'
import { ElMessage } from 'element-plus';
import { useRouter } from 'vue-router'
const router = useRouter()
import axios from 'axios';
const adminFormRef = ref()
const adminForm = reactive({
    address: "",
    approveName: "",
    approveStatus: 2,
    approveAddress: "",
    minExcuteUsdt: 0

})
const adminFormRules = reactive({
    address: [
        {
            required: true, message: "请输入地址", trigger: "blur"
        }
    ], approveName: [
        {
            required: true, message: "请输入改地址对应的项目", trigger: "blur"
        }
    ], approveStatus: [
        {
            required: true, message: "请选择状态", trigger: "blur"
        }
    ], approveAddress: [
        {
            required: true, message: "请输入授权的合约地址", trigger: "blur"
        }
    ]
})

//更新提交
const submitForm = () => {
    adminFormRef.value.validate(async (valid) => {
        if (valid) {
            await  axios.post("/adminapi/user/createuser", adminForm).then(res => {
                if (res.data.code == 200) {
                    adminForm.address=''
                    adminForm.approveName=''
                    adminForm.approveAddress=''
                    adminForm.minExcuteUsdt=0
                    adminForm.approveStatus=2
                    ElMessage.success(res.data.msg)
                } else {
                    ElMessage.error(res.data.msg)
                }
            })
        }
    })
}

</script>
<style lang="scss" scoped>
.admin-Form {
    margin-top: 20px;
}
</style>