<template>
    <div>
        <el-card>
            <el-page-header content="管理员列表" icon="" title="系统管理" />
            <el-table :data="tableData" border   style="width: 100%">
                <el-table-column prop="username" label="序号"   align="center">
                    <template #default="scope">
                        {{ scope.$index + 1 }}
                    </template>
                </el-table-column>
                <el-table-column prop="username" label="用户名"  align="center"/>
                <el-table-column label="头像"  align="center">
                    <template #default="scope">
                        <div v-if="scope.row.avatar">
                            <el-avatar :size="50" :src="$store.state.baseUrl + scope.row.avatar" />
                        </div>
                        <div v-else>
                            <el-avatar :size="50"
                                src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png" />
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="角色" align="center">
                    <template #default="scope">
                        <el-tag class="ml-2" v-if="scope.row.role === 1" type="success">管理员</el-tag>
                        <el-tag class="ml-2" v-else type="info">编辑</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="状态"  align="center">
                    <template #default="scope">
                        <el-tag class="ml-2" v-if="scope.row.state === 1" type="success">开启</el-tag>
                        <el-tag class="ml-2" v-else type="info">禁用</el-tag>
                    </template>
                </el-table-column>
                <el-table-column  label="创建时间" align="center" width="200">
                    <template #default="scope">
                        {{ formatTime.getDateTime(scope.row.createdAt) }}
                        </template>
                    </el-table-column>
                <el-table-column   label="更新日期" align="center" width="200">
                    <template #default="scope">
                        {{ formatTime.getDateTime(scope.row.updatedAt) }}
                        </template>
                    </el-table-column>
                    
                <el-table-column label="操作" width="180" align="center">
                    <template #default="scope">
                        <el-button size="small" type="primary" @click="handleEdit(scope.row)">编辑</el-button>
                        <el-popconfirm title="您确认要删除此条数据吗?" confirm-button-text="确定" cancel-button-text="取消"
                            @confirm="handleDelete(scope.row)">
                            <template #reference>
                                <el-button size="small" type="danger">删除</el-button>
                            </template>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
        <!--编辑窗口-->
        <el-dialog v-model="dialogVisible" title="编辑管理员" width="30%" :before-close="handleClose">
            <el-form ref="adminFormRef" :model="adminForm" :rules="adminFormRules" label-width="80px" class="admin-Form">
                <el-form-item label="用户名" prop="username">
                    <el-input v-model="adminForm.username" />
                </el-form-item>
                <el-form-item label="密码" prop="password">
                    <el-input v-model="adminForm.password" type="password" />
                </el-form-item>
                <el-form-item label="状态" prop="state">

                    <el-radio-group v-model="adminForm.state">
                        <el-radio :label="1">开启</el-radio>
                        <el-radio :label="2">禁用</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="角色" prop="role">
                    <el-radio-group v-model="adminForm.role">
                        <el-radio :label="1">管理员</el-radio>
                        <el-radio :label="2">编辑</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>

            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dialogVisible = false">取消</el-button>
                    <el-button type="primary" @click="handleEditConfirm()">
                        确认
                    </el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>
<script setup>
import { ref, onMounted, reactive } from 'vue'
import { ElMessage } from 'element-plus';
import formatTime from '@/util/formatTime'
import axios from 'axios';
const tableData = ref([])
const dialogVisible = ref(false)
import { useRouter } from 'vue-router'
const router = useRouter()
onMounted(() => {
    getTableData()
})
const getTableData = async () => {
    const res = await axios.get("/adminapi/admin/list")
    tableData.value = res.data.data
}
//编辑
const handleEdit = async (data) => {  
    Object.assign(adminForm, {
        id:data.id,
        username: data.username,
        state: data.state,
        role: data.state,//1是管理员  2 是编辑
    }) 
    dialogVisible.value = true 
   
}
//删除
const handleDelete = async (data) => {
    await axios.delete(`/adminapi/admin/list/${data.id}`)
    getTableData()
}

const adminFormRef = ref()
const adminForm = reactive({
    id:0,
    username: "",
    password: "",
    state: 2,
    role: 2,//1是管理员  2 是编辑
})
const adminFormRules = reactive({
    username: [
        {
            required: true, message: "请输入用户名", trigger: "blur"
        }
    ], password: [
        {
            required: false, message: "请输入密码", trigger: "blur"
        }
    ], state: [
        {
            required: true, message: "请选择状态", trigger: "blur"
        }
    ], role: [
        {
            required: true, message: "请选择角色", trigger: "blur"
        }
    ]
})
//修改数据
const handleEditConfirm= async()=>{
    adminFormRef.value.validate(async (valid) => {
        if (valid) {
            await axios.put(`/adminapi/admin/list/${adminForm.id}`,adminForm ).then(res => {
                if (res.data.code == 200) {
                    dialogVisible.value = false
                    router.push(`/system-manage/systemlist`) 
                    ElMessage.success(res.data.msg)
                    getTableData()
                   
                } else {
                    ElMessage.error(res.data.msg)
                }
            })
        }
    })
}
 
</script>
<style lang="scss" scoped>
.el-table {
    margin-top: 20px;
}
</style>