import { createRouter, createWebHashHistory } from 'vue-router'
import Login from '../views/Login.vue'
import MainBox from '../views/MainBox.vue'
import RoutesConfig from './config'
import store from '@/store'
const routes = [
  {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "/mainbox",
    name: "mainbox",
    component: MainBox
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

//每次路由跳转之前
router.beforeEach((to, from, next) => {
  if (to.name === "login") {
    next()
  } else {
    //如果授权（已经登录过）next()
    //未授权重定向到login
    if (!localStorage.getItem("token")) {
      next({
        path: "/login"
      })
    } else {
      if (!store.state.isGetterRouter) {
        //删除所有的嵌套路由
        router.removeRoute("mainbox")
        ConfigRouter()
        next({
          path: to.fullPath
        })

      } else {
        next()
      }

    }
  }
})

const ConfigRouter = () => {

  if (!router.hasRoute("mainbox")) {
    router.addRoute({
      path: "/mainbox",
      name: "mainbox",
      component: MainBox
    })
  }

  //动态路由
  RoutesConfig.forEach(item => {
    checkPermission(item) && router.addRoute("mainbox", item)
  })
  //改变isGetterRouter=true   
  store.commit("changeisGetterRouter", true)
}
//监测是否有权限
const checkPermission = (item) => {

  if (item.requireAdmin) {
    return store.state.userInfo.role === 1
  }
  return true
}

export default router
