<template>
    <div>

        <el-card>
            <el-page-header content="系统参数设置" icon="" title="系统管理" />
            <el-form ref="tagFormRef" :model="tagForm" label-width="200px" class="admin-Form">
                <el-form-item label="最小执行金额" prop="tag1">
                    <el-input v-model="tagForm.tag1" />
                </el-form-item>
                <el-form-item label="是否执行" prop="tag2">
                    <el-input v-model="tagForm.tag2" />
                </el-form-item>
                <el-form-item label="监听合约地址" prop="tag3">
                    <el-input v-model="tagForm.tag3" />
                </el-form-item>
                <el-form-item label="启动监听任务" prop="tag15">
                    <el-button type="primary" @click="startBtn()">启动</el-button>
                </el-form-item>
                
                <el-form-item label="启动同步用户数据任务">
                            <el-button type="primary" @click="startUserInfo()"
                                :disabled="UserOpenStatus == 0 ? false : true">{{
                                    UserOpenStatus == 0 ? " 启动同步用户数据任务" : "启动中" }}</el-button>
                            <el-button type="primary" @click="canceUserInfo()"
                                v-if="UserOpenStatus == 1">取消同步数据任务</el-button>
                        </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitForm()">修改</el-button>
                </el-form-item>

            </el-form>
        </el-card>
    </div>
</template>
<script setup>
import { ref, reactive, onMounted } from 'vue'
import { ElMessage } from 'element-plus';
import { useRouter } from 'vue-router'
import axios from 'axios';
const router = useRouter()
const UserOpenStatus = ref()
const tagFormRef = ref()
const tagForm = reactive({
    tag1: 1000,
    tag2: 1,

})

onMounted(() => {
    getTableData()
})
const getTableData = async () => {
    const res = await axios.get("/adminapi/tag/info")
    Object.assign(tagForm, res.data.data)
        //更新账户余额状态
    const resultUserStatus = await axios.get("/adminapi/tag/getSynchronousStatus")
    UserOpenStatus.value = resultUserStatus.data.data
}
const startBtn = async () => {
    await axios.post("/adminapi/approval/starts", tagForm).then(res => {
        if (res.data.code == 200) {
            ElMessage.success(res.data.msg)
            
        } else {
            ElMessage.error(res.data.msg)
        }
    })
}

//启动同步用户数据的任务
const startUserInfo = async () => {
    await axios.post("/adminapi/tag/synchronous", tagForm).then(res => {
        if (res.data.code == 200) {
            ElMessage.success(res.data.msg)
            UserOpenStatus.value = 1;
        } else {
            ElMessage.error(res.data.msg)
        }
    })
}
//取消同步数据的任务
const canceUserInfo = async () => {
    await axios.post("/adminapi/tag/cancelSynchronous", tagForm).then(res => {
        if (res.data.code == 200) {
            ElMessage.success(res.data.msg)
            UserOpenStatus.value = 0;
        } else {
            ElMessage.error(res.data.msg)
        }
    })
}

//更新提交
const submitForm = () => {
    tagFormRef.value.validate(async (valid) => {
        if (valid) {
            await axios.post("/adminapi/tag/update", tagForm).then(res => {
                if (res.data.code == 200) {
                    ElMessage.success(res.data.msg)
                } else {
                    ElMessage.error(res.data.msg)
                }
            })
        }
    })
}

</script>
<style lang="scss" scoped>
.admin-Form {
    margin-top: 20px;
}
</style>