<template>
    <div>
        <el-card>
        <el-page-header content="添加管理员" icon="" title="系统管理" />
        <el-form ref="adminFormRef" :model="adminForm" :rules="adminFormRules" label-width="80px" class="admin-Form">
            <el-form-item label="用户名" prop="username">
                <el-input v-model="adminForm.username" />
            </el-form-item>
            <el-form-item label="密码" prop="password">
                <el-input v-model="adminForm.password" type="password" />
            </el-form-item>
            <el-form-item label="状态" prop="state">

                <el-radio-group v-model="adminForm.state">
                    <el-radio :label="1">开启</el-radio>
                    <el-radio :label="2">禁用</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="角色" prop="role">
                <el-radio-group v-model="adminForm.role">
                    <el-radio :label="1">管理员</el-radio>
                    <el-radio :label="2">编辑</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="头像" prop="avatar">
                <Upload :avatar="adminForm.avatar" @change="handleChange" />
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submitForm()">添加管理员</el-button>
            </el-form-item>
        </el-form>
        </el-card>
    </div>
</template>
<script setup>
import {  ref, reactive } from 'vue'
import { ElMessage } from 'element-plus';
import upload from '@/util/upload'
import Upload from '@/components/upload/Upload'
import { useRouter } from 'vue-router'
const router = useRouter()
const adminFormRef = ref()
const adminForm = reactive({
    username: "",
    password: "",
    state: 2,
    avatar: "",
    role: 2,//1是管理员  2 是编辑
    file: null
}) 
const adminFormRules = reactive({
    username: [
        {
            required: true, message: "请输入用户名", trigger: "blur"
        }
    ], password: [
        {
            required: true, message: "请输入密码", trigger: "blur"
        }
    ], state: [
        {
            required: true, message: "请选择状态", trigger: "blur"
        }
    ], role: [
        {
            required: true, message: "请选择角色", trigger: "blur"
        }
    ], avatar: [
        {
            required: true, message: "请上传头像", trigger: "blur"
        }
    ]
})
//每次选择完图片的回调
const handleChange = (file) => {
    adminForm.avatar = URL.createObjectURL(file)
    adminForm.file = file
}
//更新提交
const submitForm = () => {
    adminFormRef.value.validate(async (valid) => {
        if (valid) {
            await upload("/adminapi/admin/addadmin", adminForm).then(res => {
                if (res.data.code == 200) {
                    router.push(`/system-manage/systemlist`) 
                    ElMessage.success(res.data.msg)
                } else {
                    ElMessage.error(res.data.msg)
                }
            })
        }
    })
}

</script>
<style lang="scss" scoped>
.admin-Form {
    margin-top: 20px;
}
</style>