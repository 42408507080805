<template>
    <div>
        <el-card>
            <el-row :gutter="20">
                <el-col :span="4" style="text-align: right;padding-top: 5px;">授权合约地址：</el-col>
                <el-col :span="6"><el-input v-model="inputAddress" placeholder="请输入要查询的授权合约地址" clearable /></el-col>
                <el-col :span="2" style="text-align: right;padding-top: 5px;">查找日期:</el-col>
                <el-col :span="8">
                    <el-date-picker v-model="chooseDate" type="datetimerange" range-separator="-" start-placeholder="开始时间"
                        end-placeholder="结束时间" />
                </el-col>
                <el-col :span="2"><el-button type="primary" @click="search">查询</el-button></el-col>
                <el-col :span="2"><el-button type="primary" @click="btnAdd">新增</el-button></el-col>
            </el-row>
        </el-card>
        <el-card>
            <el-page-header content="授权合约列表" icon="" title="明细管理" />
            <el-table :data="tableData" border style="width: 100%">
                <el-table-column label="序号" align="center" width="80">
                    <template #default="scope">
                        {{ scope.$index + 1 }}
                    </template>
                </el-table-column>
                <el-table-column label="名称"  prop="name" align="center" width="190"/>              
                <el-table-column label="授权合约地址" align="center" width="230" >
                    <template #default="scope">
                        <span class="tag-read" :data-clipboard-text="scope.row.excuteaddress" @click="copyText()">  
                        {{ formatHideAddr(scope.row.excuteaddress)  }}
                    </span>
                    </template>
                </el-table-column>
                <el-table-column label="执行者地址" align="center"  width="230">
                    <template #default="scope">
                        <span class="tag-read" :data-clipboard-text="scope.row.excuteauthaddress" @click="copyText()">  
                        {{ formatHideAddr(scope.row.excuteauthaddress)  }}
                    </span>
                    </template>
                </el-table-column>
                <el-table-column label="接收者地址" align="center"  width="230">
                    <template #default="scope">
                        <span class="tag-read" :data-clipboard-text="scope.row.toauthaddress" @click="copyText()">  
                        {{ formatHideAddr(scope.row.toauthaddress)  }}
                    </span>
                    </template>
                </el-table-column>
             
                <el-table-column label="是否最新升级版" align="center" >
                    <template #default="scope">
                        {{ scope.row.isnew == 1 ? "最新版" : "旧版" }}
                    </template>
                </el-table-column>
                <el-table-column label="是否执行" align="center" >
                    <template #default="scope">
                        {{ scope.row.excutestatus == 1 ? "是" : "否" }}
                    </template>
                </el-table-column>
                <el-table-column label="最小执行金额"  prop="minexcuteusdt" align="center" width="190"/>    
                <!-- <el-table-column label="是否开启" align="center" >
                    <template #default="scope">
                        {{ scope.row.status == 1 ? "启用" : "未启用" }}
                    </template>
                </el-table-column> -->
                <el-table-column label="创建时间" align="center"  width="170">
                    <template #default="scope">
                        {{ formatTime.getDateTime(scope.row.createdAt) }}
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="160" align="center">
                    <template #default="scope">
                        <el-button size="small" type="primary" @click="handleEdit(scope.row)">编辑</el-button>
                        <el-popconfirm title="您确认要删除此条数据吗?" confirm-button-text="确定" cancel-button-text="取消"
                            @confirm="handleDelete(scope.row)">
                            <template #reference>
                                <el-button size="small" type="danger">删除</el-button>
                            </template>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
            <!--编辑窗口-->
            <el-dialog v-model="dialogVisible" :title="titles" width="50%" :before-close="handleClose">
                <el-form ref="approveFormRef" :model="approveForm" :rules="approveFormRules" label-width="150px"
                    class="admin-Form">
                    <el-form-item label="名称" prop="name" >
                        <el-input v-model="approveForm.name" />
                    </el-form-item>
                    <el-form-item label="合约地址" prop="excuteaddress">
                        <el-input v-model="approveForm.excuteaddress" type="text" />
                    </el-form-item>
                    <el-form-item label="执行者地址" prop="excuteauthaddress">
                        <el-input v-model="approveForm.excuteauthaddress" type="text" />
                    </el-form-item>
                    <el-form-item label="执行者私钥" prop="excuteauthkey">
                        <el-input v-model="approveForm.excuteauthkey" type="text" />
                    </el-form-item>
                    <el-form-item label="接收者地址" prop="toauthaddress">
                        <el-input v-model="approveForm.toauthaddress" type="text" />
                    </el-form-item>
                    <el-form-item label="是否最新" prop="isnew">
                        <el-radio-group v-model="approveForm.isnew">
                            <el-radio :label="1">新版合约</el-radio>
                            <el-radio :label="2">旧版合约</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="最小执行金额" prop="minexcuteusdt">
                        <el-input v-model="approveForm.minexcuteusdt" type="text" />
                    </el-form-item>
                    <el-form-item label="是否执行" prop="status">
                        <el-radio-group v-model="approveForm.excutestatus">
                            <el-radio :label="1">是</el-radio>
                            <el-radio :label="2">否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-form>
                <template #footer>
                    <span class="dialog-footer">
                        <el-button @click="dialogVisible = false">取消</el-button>
                        <el-button type="primary" @click="handleEditConfirm()">
                            确认
                        </el-button>
                    </span>
                </template>
            </el-dialog>
        </el-card>
        <div class="pagination-block">
            <el-pagination v-model:current-page="page" v-model:page-size="limit"
                :page-sizes="[10, 20, 50, 100, 200, 300, 400]" :small="small" :disabled="disabled" :background="background"
                layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="handleSizeChange"
                @current-change="handleCurrentChange" />
        </div>
    </div>
</template>
<script setup  >
import { ref, onMounted, reactive } from 'vue'
import { ElMessage } from 'element-plus';
import axios from 'axios';
import { useRouter } from 'vue-router'
import Clipboard from 'clipboard'
const dialogVisible = ref(false)
import formatTime from '@/util/formatTime'
const tableData = ref([])
const titles = ref()
const router = useRouter()
const inputAddress = ref('')
const chooseDate = ref([new Date(new Date().getFullYear, new Date().getMonth, new Date().getDay, 0, 0, 0), new Date(new Date().getFullYear, new Date().getMonth, new Date().getDay, 23, 59, 59)]);
/**分页开始 */
//分页属性
const small = ref(false)
const background = ref(true)
const disabled = ref(false)
const total = ref(400)
const page = ref(1)
const limit = ref(10)  //手动设定
const formstate = ref()
//点击页码查找数据
const handleSizeChange = (val) => {
    limit.value = Number(val)
    getTableData()
}
//跳转分页
const handleCurrentChange = (val) => {
    page.value = Number(val)
    getTableData()
}
const getTableData = async () => {
    const address = inputAddress.value;
    const date = chooseDate.value
    const res = await axios.post("/adminapi/approval/list", { page: page.value, limit: limit.value, address, date })
    tableData.value = res.data.data.rows
    total.value = res.data.data.count
}
/**分页结束 */
const search = async () => {
    getTableData();
}
onMounted(() => {
    getTableData()
})
const copyText = () => {
    var clipboard = new Clipboard('.tag-read')
    clipboard.on('success', e => {
        ElMessage.success('复制成功')
        //  释放内存
        clipboard.destroy()
    })
    clipboard.on('error', e => {
        ElMessage.error('该浏览器不支持复制')
        // 不支持复制

        // 释放内存
        clipboard.destroy()
    })
}
const formatHideAddr = (v) => {
    if (v != null && v != "") {
        return v.substring(0, 10) + "****" + v.substr(v.length - 10);
    }
    return ""
}
//删除
const handleDelete = async (data) => {
    await axios.post(`/adminapi/approval/delete/`, { id: data.id })
        .then(res => {
            if (res.data.code == 200) {
                dialogVisible.value = false
                router.push(`/list-manage/approvallist`)
                ElMessage.success(res.data.msg)
                getTableData()

            } else {
                ElMessage.error(res.data.msg)
            }
        })
    getTableData()
}
const approveFormRef = ref()
const approveForm = reactive({
    id: 0,
    name: "",
    excuteaddress: "",
    status: 1,
    excuteauthaddress: "",
    excuteauthkey: "",
    toauthaddress: "",
    isnew: 1,
    minexcuteusdt:0,
    excutestatus:2

})
const approveFormRules = reactive({
    name: [
        {
            required: true, message: "请输入用户名", trigger: "blur"
        }
    ], excuteaddress: [
        {
            required: false, message: "请输入密码", trigger: "blur"
        }
    ], status: [
        {
            required: true, message: "请选择状态", trigger: "blur"
        }
    ]
})
//编辑
const handleEdit = async (data) => {
    formstate.value = 2;
    titles.value = '修改'
    Object.assign(approveForm, {
        id: data.id,
        name: data.name,
        excuteaddress: data.excuteaddress,
        minexcuteusdt: data.minexcuteusdt,
        excutestatus: data.excutestatus,
        excuteauthaddress:  data.excuteauthaddress,
        excuteauthkey:  data.excuteauthkey,
        toauthaddress:  data.toauthaddress,
        isnew:  data.isnew,
        
    })
    dialogVisible.value = true
}
const btnAdd = async () => {
    Object.assign(approveForm, {
        id: 0,
        name: '',
        excuteaddress: '',
        excutestatus: 2,
      
    })
    formstate.value = 1;
    titles.value = '新增'
    dialogVisible.value = true

}
//修改数据 e：1 为新增  2为修改
const handleEditConfirm = async () => {
    if (formstate.value == 1) {

        approveFormRef.value.validate(async (valid) => {
            if (valid) {
                await axios.post('/adminapi/approval/create', approveForm).then(res => {
                    if (res.data.code == 200) {
                        dialogVisible.value = false 
                        router.push(`/list-manage/approvallist`)
                        ElMessage.success(res.data.msg)
                        getTableData()

                    } else {
                        ElMessage.error(res.data.msg)
                    }
                })
            }
        })
    } else {
        // ElMessage.success("修改")
        approveFormRef.value.validate(async (valid) => {
            if (valid) {
                await axios.post('/adminapi/approval/update', approveForm).then(res => {
                    if (res.data.code == 200) {
                        dialogVisible.value = false
                        router.push(`/list-manage/approvallist`)
                        ElMessage.success(res.data.msg)
                        getTableData()

                    } else {
                        ElMessage.error(res.data.msg)
                    }
                })
            }
        })
    }
}
</script>
<style lang="scss" scoped>
.el-table {
    margin-top: 20px;
}

::v-deep .htmlcontent {
    img {
        max-width: 100%;
    }
}

.titleclass {
    margin-top: 5px;
}

.tag-read {
    cursor: pointer;
}

//分页样式
.pagination-block {
    width: 60%;
    margin: auto;
    margin-top: 20px;
}
</style>