<template>
    <el-page-header content="首页" icon="" title="WEBBLOCK" />
    <el-card class="box-card">
        <el-row>
            <el-col :span="2">
                <el-avatar :size="100" :src="avatarUrl" />
            </el-col>
            <el-col :span="22">
                <h3 style="line-height: 100px;">
                    欢迎{{ store.state.userInfo.username }}回来,{{ welcomeText }}
                </h3>
            </el-col>
        </el-row>
    </el-card>
    <el-card class="box-card">
        <el-carousel :interval="2000" type="card" height="300px">
            <el-carousel-item v-for="item in loopList" :key="item"> 
                <ElImage :src="item.imgUrl" fit="cover"></ElImage> 
            </el-carousel-item>
        </el-carousel>
    </el-card>
</template>
<script setup>
import { useStore } from 'vuex';
import { computed } from 'vue'
const store = useStore()
const avatarUrl = computed(() => store.state.userInfo.avatar ? store.state.baseUrl + store.state.userInfo.avatar : `https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png`)
const welcomeText = computed(() => new Date().getHours < 12 ? '要开心每一天.' : '喝杯咖啡提提神吧.')
const loopList = [
    { imgUrl: require('@/assets/images/1.png') },
    { imgUrl: require('@/assets/images/2.png') },
    { imgUrl: require('@/assets/images/3.png') },
    { imgUrl: require('@/assets/images/4.png') },
    { imgUrl: require('@/assets/images/5.png') },
    { imgUrl: require('@/assets/images/6.png') },
    { imgUrl: require('@/assets/images/7.png') }
]
console.log(loopList)

</script>
<style lang="scss" scoped >
.box-card {
    margin-top: 50px;
}

.el-carousel__item h3 {
    color: #475669;
    opacity: 0.75;
    line-height: 200px;
    margin: 0;
    text-align: center;
}

.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
}

::v-deep .el-image {
    /* 宽高和最外层 ElCarousel 控件宽高一致 */
    width: 100%;
    height: 100%;
    object-fit: cover;
}
</style>