<template>
    <div>
        <vue-particles id="tsparticles" :particlesInit="particlesInit" :options="options" />
        <div class="formContainer">
            <h3>WEBBLOCK管理自动化系统</h3>
            <el-form ref="LoginFormRef" :model="LoginForm" status-icon :rules="loginRules" label-width="80px"
                class="loginForm">
                <el-form-item label="用户名" prop="username" style="padding-top: 20px;">
                    <el-input v-model="LoginForm.username" type="text" autocomplete="on" placeholder="请输入账号"/>
                </el-form-item>

                <el-form-item label="密码" prop="password" style="padding-top: 20px;">
                    <el-input v-model="LoginForm.password" type="password" autocomplete="on" placeholder="请输入密码"/>
                </el-form-item>
                <el-form-item  style="padding-top: 20px;">
                    <el-button type="primary" @click="submitForm()" style="margin: auto;width: 100px;">登录</el-button> 
                    <el-button type="primary" @click="submitClear()" style="margin: auto;width: 100px;">清除</el-button> 
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script setup>
import { loadSlim } from "tsparticles-slim"; // if you are going to use `loadSlim`, install the "tsparticles-slim" package too.
import { reactive, ref } from "vue";
import { useRouter } from 'vue-router'
import { ElMessage } from "element-plus";
import axios from 'axios'
import { useStore } from 'vuex'
const store = useStore()
//表单绑定的响应式对象
const LoginForm = reactive({
    username: "",
    password: ""
})
//表单的引用对象
const LoginFormRef = ref()
//校验规则
const loginRules = reactive({
    username: [
        {
            required: true, message: "请输入用户名", trigger: "blur"
        }
    ], password: [
        {
            required: true, message: "请输入密码", trigger: "blur"
        }
    ]
})

const particlesInit = async engine => {
    //await loadFull(engine);
    await loadSlim(engine);
};
const router = useRouter()
const submitClear=()=>{ 
    //清除
    LoginForm.username=''
    LoginForm.password=''
}
//登录
const submitForm = () => {
    //1.效验表单
    LoginFormRef.value.validate((valid) => {

        if (valid) {
            //2.拿到表单内容，提交后台/adminapi/user/login
            axios.post('/adminapi/admin/login', LoginForm).then(res => { 
                if (res.data.code == 200) {
                    store.commit("changeUserInfo",res.data.data)
                    //重置权限
                    store.commit("changeisGetterRouter",false)
                    router.push("/index")
                } else {
                    ElMessage.error(res.data.msg) 
                }

            })


        }
    }) 
}
//配置粒子库tsparticles属性
const options = {
    background: {
        color: {
            value: '#2d3a4b'
        }
    },
    fpsLimit: 120,
    interactivity: {
        events: {
            onClick: {
                enable: true,
                mode: 'push'
            },
            onHover: {
                enable: true,
                mode: 'repulse'
            },
            resize: true
        },
        modes: {
            bubble: {
                distance: 400,
                duration: 2,
                opacity: 0.8,
                size: 40
            },
            push: {
                quantity: 4
            },
            repulse: {
                distance: 200,
                duration: 0.4
            }
        }
    },
    particles: {
        color: {
            value: '#ffffff'
        },
        links: {
            color: '#ffffff',
            distance: 150,
            enable: true,
            opacity: 0.5,
            width: 1
        },
        collisions: {
            enable: true
        },
        move: {
            direction: 'none',
            enable: true,
            outModes: {
                default: 'bounce'
            },
            random: false,
            speed: 6,
            straight: false
        },
        number: {
            density: {
                enable: true,
                area: 800
            },
            value: 80
        },
        opacity: {
            value: 0.5
        },
        shape: {
            type: 'circle'
        },
        size: {
            value: { min: 1, max: 5 },
        }
    },
    detectRetina: true
}
</script>
<style lang="scss" scoped>
.formContainer {
    width: 500px;
    height: 300px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: rgb($color: #000000, $alpha: 0.5);
    color: #fff;
    text-align: center;
    padding: 20px;

    h3 {
        font-size: 30px;
    }

    .loginForm {
        margin-top: 20px;
    }

}

::v-deep .el-form-item__label {
    color: #fff;
}
</style>