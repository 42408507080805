import Home from '../views/home/Home.vue'
import Center from '../views/center/Center.vue'

import UserList from '../views/user-manage/UserList.vue'
import SystemAdd from '../views/system-manage/SystemAdd.vue'
import SystemList from '../views/system-manage/SystemList.vue'
import SystemSet from '../views/system-manage/SystemSet.vue'
import LogList from '../views/log-manage/LogList.vue'
import NotFound from '../views/notfound/NotFound.vue'
import InMoneyList from '../views/list-manage/InMoneyList.vue'
import AddUser from  '../views/user-manage/AddUser.vue'
import ApprovalList from '../views/list-manage/ApprovalList.vue'

const routes=[
    {
        path: '/index',
        component: Home
      },{
        path: '/center',
        component: Center
      },{
        path: '/system-manage/addsystem',
        component: SystemAdd,
        requireAdmin:true
      }
      ,{
        path: '/system-manage/systemlist',
        component: SystemList,
        requireAdmin:true
      } ,{
        path: '/system-manage/systemset',
        component: SystemSet,
        requireAdmin:true
      } 
      ,{
        path: '/user-manage/adduser',
        component: AddUser
      }
      ,{
        path: '/user-manage/userlist',
        component: UserList
      } ,{
        path: '/',
        redirect: "/index"
      },{
        path: '/:pathMathch(.*)*',
        name:'NotFound',
        component: NotFound
      },{
        path: '/list-manage/inmoneylist',
        component: InMoneyList
      } ,
      
      {
        path: '/log-manage/loglist',
        component: LogList
      },
    
      {
        path: '/list-manage/approvallist',
        component: ApprovalList
      }
      
]

export default routes