<template>
    <div>
        <el-card>
            <el-page-header content="日志记录" icon="" title="日志管理" />
            <el-table :data="tableData" border style="width: 100%">
                <el-table-column label="序号" align="center">
                    <template #default="scope">
                        {{ scope.$index + 1 }}
                    </template>
                </el-table-column>
                <el-table-column label="操作者" prop="uname" align="center" />
                <el-table-column label="功能板块" prop="platefunction" align="center" />
                <el-table-column label="描述" prop="description" align="center" />

                <el-table-column label="创建时间" width="180" align="center">
                    <template #default="scope">
                        {{ formatTime.getDateTime(scope.row.createdAt) }}
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
        <div class="pagination-block">
            <el-pagination v-model:current-page="page" v-model:page-size="limit" :page-sizes="[10, 20, 50, 100, 200, 300, 400]"
                :small="small" :disabled="disabled" :background="background"
                layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="handleSizeChange"
                @current-change="handleCurrentChange" />
        </div>

    </div>
</template>
<script setup>
import { ref, onMounted, reactive } from 'vue'
import { ElMessage } from 'element-plus';
import Clipboard from 'clipboard'
import axios from 'axios';
import { useRouter } from 'vue-router'
import formatTime from '@/util/formatTime'
const tableData = ref([])
/**分页开始 */
//分页属性
const small = ref(false)
const background = ref(true)
const disabled = ref(false)
const total = ref(400)
const page = ref(1)
const limit = ref(10)  //手动设定
//点击页码查找数据
const handleSizeChange = (val) => {
    limit.value = Number(val)
    getTableData()
}
//跳转分页
const handleCurrentChange = (val) => {
    page.value = Number(val)
    getTableData()
}
const getTableData = async () => {
    const res = await axios.post("/adminapi/log/list", { page: page.value, limit: limit.value })
    tableData.value = res.data.data.rows
    total.value = res.data.data.count
}
/**分页结束 */
onMounted(() => {
    getTableData()
})

</script>
<style lang="scss" scoped>
.el-table {
    margin-top: 20px;
}

::v-deep .htmlcontent {
    img {
        max-width: 100%;
    }
}

.titleclass {
    margin-top: 5px;
}

.tag-read {
    cursor: pointer;
}

//分页样式
.pagination-block {
    width: 60%;
    margin: auto;
    margin-top: 20px;

}
</style>